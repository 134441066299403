
/* eslint-disable */
import { computed, defineComponent, onMounted, toRef, ref, inject } from "vue";
import Countries from "@/core/services/Countries";
import { store } from "@/store";

export default defineComponent({
  props: {
    isType: {
      type: String,
      required: true,
      default: "text",
    },
    label: {
      type: String,
      required: true,
      default: "First Name",
    },
    formProp: {
      type: String,
      required: true,
      default: "first_name",
    },
    modelValue: {
      type: String,
      required: true,
      default: "",
    },
    countryCode: {
      type: String,
      required: false,
      default: "",
    },
    placeHolder: {
      type: String,
      required: false,
      default: "",
    },
    tooltipMessage: {
      type: String,
      required: false,
      default: "",
    },
    maxLength: {
      type: String,
      required: false,
    },
    onKey: {
      type: String,
      required: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
    options: {
      type: Array,
      required: false,
    },
    isGroup: {
      type: String,
      required: false,
      default: "No",
    },
  },

  setup(props, context) {
    const isType = toRef(props, "isType");
    const isMobile = inject('isMobile')

    onMounted(async () => {
      if (isType.value === "phone") await Countries.getCountryCode();
    });

    const countries = computed(() => {
      return store.state.countries.countryCode;
    });

    const updateValue = (event) => {
      context.emit("update:modelValue", event);
      context.emit("input", event);
    };

    const updateCountryValue = (event) => {
      context.emit("update:countryCode", event);
      context.emit("change", event);
    };

    const name = ref()
    const focus = () => {
      setTimeout(() => {
        name.value.focus()
      }, 500);
    }

    const colSize = (props.formProp == 'insured_city' || props.formProp == 'phone_number' || props.formProp == 'expected_premium') 
                        ? (props.isGroup == "Yes") ? 'col-lg-10' : 'col-lg-12' 
                        : 'col-lg-10';
    // console.log(">>>>>>>>>>>formProp");
    // console.log(props.formProp+' -> '+colSize);

    return {
      name,
      focus,
      updateValue,
      updateCountryValue,
      countries,
      isType,
      colSize,
      isMobile
    };
  },
});
