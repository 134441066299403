import { ActionTree, ActionContext } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { DeclarationsMutationTypes } from '@/core/enum/Declarations/Mutations';
import { DeclarationsActionTypes } from '@/core/enum/Declarations/Actions';
import { Links } from '@/core/enum/Links';
import { healthDeclarations } from '@/core/types';
import axios from 'axios';
import router from '@/router';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [DeclarationsActionTypes.GET_DECLARATIONS](
    { commit }: AugmentedActionContext
  );
  [DeclarationsActionTypes.POST_DECLARATION](
    { commit }: AugmentedActionContext,
    payload: healthDeclarations
  );
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [DeclarationsActionTypes.GET_DECLARATIONS]({ commit }) {
    try {
      const payload = {
        lang: window.localStorage.getItem('language'),
        isDropdown: true
      }
      const data = await axios.post(Links.GET_DECLARATIONS, payload);
      const declarations = data.data.data.declaration;
      const val = declarations.map(x => {
        return {
          answer: x.answer === 'NO' ? false : true,
          id: x.id,
          question: x.question,
          question_ar: x.question_ar,
          members: []
        }
      })
      commit(DeclarationsMutationTypes.SET_DECLARATIONS, val);
    }
    catch (err) {
      return err;
    }
  },

  async [DeclarationsActionTypes.POST_DECLARATION]({ commit }, payload) {
    try {
      const data = await axios.post(Links.POST_HEALTH_DECLARATIONS, payload);
      const result = data.data;
      commit(DeclarationsMutationTypes.SET_DECLARATION_RESULT, result);
      window.localStorage.setItem('health_declaration_submitted', JSON.stringify(payload));

      if (!payload.is_present) {
        await axios.post(Links.SEND_CHEAP_QUOTES, payload);
        const map_id = window.localStorage.getItem('map_id')
        const route = router.resolve({ name: 'CompareQuotes', query: { map_id: map_id } });
        // const route = router.resolve({ name: 'CompareQuotes' })
        window.location.href = route.href;
      window.localStorage.setItem('health_declaration_issue','NO');

      }
      else {
      window.localStorage.setItem('health_declaration_issue','YES');

        const route = router.resolve({ name: 'ThankYou' });
        window.location.href = route.href;
      }

      return result;
    } catch (err) {
      return err;
    }
  }
};
