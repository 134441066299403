
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, ref, watchEffect } from "vue";
import { formPersonalDetails } from "@/store/stateless";
import { emirates, northern_emirate } from "@/core/utils";
import { store } from "@/store";
import Validations from "@/core/validations";
import { focus, dates, thankYouPopup } from "@/store/stateless"
import personalDetails from "@/core/services/PersonalDetails";
import { Member } from "@/core/types";
import router from "@/router";

export default defineComponent({
  name: 'otherComponent',
  // eslint-disable-next-line
  setup(_) {

    
    const lang = computed(() => {
      const lang = window.localStorage.getItem("language");
      if(lang)
        return lang;

      return 'en';
    });

    const showMembers = ref(true);
    const salary = ref('4,000');
    const is_salary = ref(true);

    const customer = computed(() => {
      return store.state.personals.customer;
    });

    const loading = computed(() => {
      return store.state.personals.loading;
    });

    const rules = reactive({
      name: [{ validator: Validations.names, trigger: ["blur", "change"] }],
      phone_number: [
        { validator: Validations.phone_number, trigger: ["blur", "change"] },
      ],
      insured_city: [
        { validator: Validations.emirate, trigger: ["blur", "change"] },
      ],
      insured_type_id: [
        { validator: Validations.insured_type, trigger: ["blur", "change"] },
      ],
      dob: [{ validator: Validations.dob, trigger: ["blur", "change"] }],
      expected_insurance_start_date: [
        { validator: Validations.insurance_date, trigger: ["blur", "change"] },
      ],
      nationality_id: [
        { validator: Validations.nationality, trigger: ["blur", "change"] },
      ],
      gender: [{ validator: Validations.gender, trigger: ["blur", "change"] }],
      email: [{ validator: Validations.email, trigger: ["blur", "change"] }],
    });

    onMounted(async () => {
      is_salary.value = (customer.value.is_salary && customer.value.is_salary == true) ? true : false;
      // console.log('other',is_salary.value)

      if (customer.value.phone_number) await getLatestLeads();

      if (customer.value.members.length == 0)
        showMembers.value = false;

      const callBack = window.localStorage.getItem('callBack');

      if (callBack)
        showMembers.value = false

        if (focus.personal !== '') {
          // eslint-disable-next-line
          const toFocus: any = inst?.refs[focus.personal]
          if (toFocus !== undefined) {
            toFocus.focus()
          }
        }

    })


    const memberForm = ref();

    const getExistLeads = async () => {
      await getLatestLeads()
      await callLoad()
    }

    const removeRenewals = () => {
      const isRenewal = window.sessionStorage.getItem('renewal');
      if(isRenewal){
        router.replace({query: undefined});
        window.sessionStorage.removeItem('renewal');
        window.sessionStorage.removeItem('cid');
      }
    }

    const callLoad = async () => {
      removeRenewals()
      // console.log('cus',store.state.personals.customer)
      const customer = store.state.personals.customer;
      personalDetails.setExistClick(false);
      personalDetails.setCustomer({
        id: customer.id,
        lang: customer.lang,
        name: customer.name,
        email: customer.email ?? null,
        contact_person: null,
        phone_number: customer.phone_number,
        phone_country_code: customer.phone_country_code,
        insured_city: customer.insured_city,
        insured_type_id: customer.insured_type_id,
        insurance_type: 1,
        is_salary: customer.is_salary,
        expected_insurance_start_date: null,
        expected_insurance_start_day: "",
        expected_insurance_start_month: "",
        expected_insurance_start_year: "",
        customer_source: null,
        is_health: 1,
        utm_source: null,
        utm_medium: null,
        utm_campaign: null,
        utm_content: null,
        age: 0,
        dob: customer.dob,
        dob_day: "",
        dob_month: "",
        dob_year: "",
        nationality_id: customer.nationality_id,
        gender: customer.gender,
        company_name: null,
        is_self: null,
        agent: null,
        health_lead_map_id: 0,
        is_company: false,
        deleted_members: [],
        members: [],
        categories: [{
          id: null,
          name: null,
          groups: [{
            id: null,
            age_group: null,
            plan: null,
            group_count: 1
          }]
        }],
        query: {},
        is_web: true,
        is_investor: customer.is_investor
      })
      // formPersonalDetails.value?.resetFields()
      // console.log(memberForm.value)
      if (memberForm.value) await memberForm.value.forEach(x => x.relationship?.load());
    };

    const inst = getCurrentInstance()
    watchEffect(async () => {
      // console.log('customer',customer.value)
      

      if (customer.value && customer.value.members.length == 1) {
        const callBack = window.localStorage.getItem('callBack');
        if (callBack)
          showMembers.value = false
      }

      if(customer.value && customer.value.members.length > 0){
        showMembers.value = true;
        const sponsor = customer.value?.members.filter(x => x.relationship === 'Sponsor');
        if(sponsor.length == 0)
        {
          customer.value.is_self = false;
        }
        const x = sponsor[0];
        if (x && x.relationship === 'Sponsor') {
           customer.value.name = x.member_name;
           customer.value.age = x.age;
           customer.value.gender = x.gender;
           customer.value.dob = x.dob;
           customer.value.nationality_id = x.nationality_id;
          //  customer.value.is_salary = x.is_salary;
        }
      }

      

    })

    const updateSponsorValues = () => {
      console.log('customer updates')

      customer.value.members = customer.value.members.map((x) => {
        if (x.relationship === 'Sponsor') {
          x.member_name = customer.value.name;
          x.age = customer.value.age;
          x.gender = customer.value.gender;
          x.dob = customer.value.dob;
          x.nationality_id = customer.value.nationality_id;
          x.is_salary = customer.value.is_salary;
        }
        return x;
      })
    }


    const addMeToSponsor = (members: Array<Member>) => {

      customer.value.is_self = !customer.value.is_self
      if (customer.value.is_self) {
        const sponsor = customer.value?.deleted_members?.find(x => x.relationship === 'Sponsor');
        if(sponsor){
            customer.value?.members?.push(sponsor);
            customer.value.deleted_members = customer.value?.deleted_members.filter(x => x.relationship != 'Sponsor') 
        }
        else {
          const health: Member = {
            id: null,
            lead_id: 0,
            member_name: customer.value.name,
            relationship: 'Sponsor',
            age: customer.value.age,
            gender: customer.value.gender,
            dob: customer.value.dob,
            nationality_id: customer.value.nationality_id,
            is_salary: customer.value.is_salary,
            declaration_answers: [],
            is_married: customer.value.gender === 'FEMALE' ? true : false,
            is_editable: false,
            is_temp: 0,
            temp_record: 0
          }
          // console.log(health)
          members.unshift(health);
        }
        personalDetails.setRelationships(true)

      } else {
        const sponsor = customer.value?.members?.find(x => x.relationship === 'Sponsor');
        if (sponsor) {
            customer.value.deleted_members.push(sponsor);
            customer.value.members = customer.value?.members.filter(x => x.relationship != 'Sponsor') 
        }
        personalDetails.setRelationships(false)
      }

    }

    const getLatestLeads = async () => {
      return await personalDetails.getLatestLeads(customer.value)
    }

    const disabledInsuredType = computed(() => {
      return store.state.personals.insured_type_disabled
    })

    const addMemberForm = () => {
      showMembers.value = true;
      thankYouPopup.open = false;
      if (customer.value.members.length == 0) {
        customer.value.members.push({
          id: null,
          lead_id: 0,
          member_name: null,
          relationship: null,
          gender: null,
          dob: null,
          age: 0,
          nationality_id: null,
          is_salary: false,
          declaration_answers: [],
          is_married: false,
          is_editable: true,
          is_temp: 0,
          temp_record: 0

        })
      }
    }

    const addNewMemberForm = () => {
      customer.value.members.push({
        id: null,
        lead_id: 0,
        member_name: null,
        relationship: null,
        gender: null,
        dob: null,
        age: 0,
        nationality_id: null,
        is_salary: false,
        declaration_answers: [],
        is_married: false,
        is_editable: true,
        is_temp: 0,
        temp_record: 0

      })
    }

    const requestCallBack = () => {
      window.location.href = '/thankyou'
    }

    const changeEmirate = () => {

      customer.value.is_investor = false
      // console.log('change',customer.value.insured_city)
      if (customer.value.insured_city && northern_emirate.includes(customer.value.insured_city)) {
        salary.value = '15,000';
      }
      else {
        salary.value = '4,000';

      }

      // console.log('salary',salary.value)
    }

    const updateSalaryValue = () => {
      console.log('old-salary',customer.value.is_salary);
      // is_salary.value = !is_salary.value;
      customer.value.is_salary = !customer.value.is_salary;
      // customer.value.is_salary = is_salary.value;
      console.log('update-salary',customer.value.is_salary);

    }


    const emailEvent = () => {
            Validations.email_taken = false
    }

    return {
      disabledInsuredType,
      focus,
      getExistLeads,
      callLoad,
      loading,
      formPersonalDetails,
      emirates,
      customer,
      rules,
      dates,
      memberForm,
      getLatestLeads,
      addMeToSponsor,
      addMemberForm,
      thankYouPopup,
      showMembers,
      requestCallBack,
      salary,
      changeEmirate,
      updateSponsorValues,
      northern_emirate,
      addNewMemberForm,
      updateSalaryValue,
      is_salary,
      emailEvent,
      lang,
      inst
    };
  },
});
