/* eslint-disable */
import { App, getCurrentInstance } from "vue";
import { FormInstance } from "element-plus";
import { Member } from "@/core/types";
import pd from "@/core/services/PersonalDetails";
import { store } from "@/store";
import { focus, thankYouPopup, insuredFamilyValue, insuredParentValue } from "@/store/stateless"
import router from "@/router";
import Validations from "@/core/validations";


export const personalDetails = (app: App<Element>) => {
    const dates = app.config.globalProperties.dates;
    app.config.globalProperties.personalDetails = {
        async click(formEl: FormInstance , inst) {
            if (!formEl) return;
            formEl.validate(async (valid, fields: any) => {
                if (valid) {
                    pd.setLoading(true);
                    const customer = store.state.personals.customer
                    
                    customer.query = router.currentRoute.value.query
                    customer.age = dates.getAge(customer.dob);

                    // remove empty Member Form
                    let newMembers = customer.members.filter(x => {
                        console.log('memberdetails',x.member_name);
                        if(x.id == null)
                        {
                            if( x.member_name != null && x.dob != null && x.nationality_id != null && x.relationship != null && x.gender != null )
                            {
                                return x;
                            }
                            return false;
                        }
                        else{
                            // if( x.member_name != null && x.dob != null && x.nationality_id != null && x.relationship != null && x.gender != null )
                            if( x.member_name != null )
                            {
                                return x;
                            }
                            else
                                return false
                        }
                    })
                    console.log(newMembers)
                    customer.members = newMembers;

                    // in case members is empty and deleted members is not empty
                    if(customer.members.length == 0 && customer.deleted_members.length != 0){
                        // put a temp value
                        customer.members.push({
                            id: null,
                            lead_id: 0,
                            member_name: customer.name,
                            relationship: '',
                            age: customer.age,
                            gender: customer.gender,
                            dob: customer.dob,
                            nationality_id: customer.nationality_id,
                            is_salary: customer.is_salary,
                            declaration_answers: [],
                            is_married: customer.gender === 'FEMALE' ? true : false,
                            is_editable: false,
                            is_temp:0,
                            temp_record: 1
                         });
                    }


                    if(customer.members.length == 0)
                    {
                        window.localStorage.setItem('callBack','1');
                    }
                    else{
                        window.localStorage.removeItem('callBack');
                    }
                    console.log('memberdetails',customer.members);


                    customer.utm_source = router.currentRoute.value.query?.utm_source ?  router.currentRoute.value.query.utm_source.toString() : '';
                    customer.utm_medium = router.currentRoute.value.query?.utm_medium ?  router.currentRoute.value.query.utm_medium.toString() : '';
                    customer.utm_campaign = router.currentRoute.value.query?.utm_campaign ?  router.currentRoute.value.query.utm_campaign.toString() : '';
                    customer.utm_content = router.currentRoute.value.query?.utm_content ?  router.currentRoute.value.query.utm_content.toString() : '';
                    // customer.insurance_type = router.currentRoute.value.query?.insurance_type ? parseInt(router.currentRoute.value.query.insurance_type.toString()) : 1;
                    const sessionId = window.sessionStorage.getItem('session_id');
     
                    if(sessionId)
                        customer.session_id = sessionId;

    
                    
                    const isRenewal = window.sessionStorage.getItem('renewal');
                    customer.is_renewal = false;

                    if(isRenewal){
                        customer.is_renewal = true;
                        const cid = window.sessionStorage.getItem('cid');
                        if(cid)
                            customer.cid = cid;
                    }
                    

                    console.log('customer in api payload',customer);

                    pd.setCustomer(customer)
                    const result = await pd.post(customer);
                    console.log('tests2',result);

                    if (result.status !== 200 && result.response) {
                         console.log('tests2');

                        // const { email_taken, phone_taken } = result.response.data.data
                        // Validations.email_taken = email_taken
                        // Validations.phone_taken = phone_taken
                        formEl.validate(async (valid, field: any) => {
                            focus.personal = ''
                            formEl.scrollToField(Object.keys(field)[0])
                            focus.personal = Object.keys(field)[0]
                            focus.member = ''
                        })
                    }
                    

                    pd.setLoading(false);
                } else {
                    focus.personal = ''
                    formEl.scrollToField(Object.keys(fields)[0] + "_label")
                    focus.personal = Object.keys(fields)[0]
                    focus.member = ''

                    // const inst = getCurrentInstance()
                    console.log(inst)
                    const toFocus: any = inst?.refs[Object.keys(fields)[0]]
                    console.log(toFocus,Object.keys(fields)[0])
                    if (toFocus !== undefined) {
                        toFocus.focus()
                    }
                }
            })
        },

        reset(formEl: FormInstance | undefined) {
            if (!formEl) return
            formEl.clearValidate()
        },

        deleteMember(members: Array<Member>, index: number) {
            const customer = store.state.personals.customer

            if(!customer.deleted_members)
                customer.deleted_members = []
                
            customer.deleted_members.push(members[index])
            if(members[index].relationship == 'Sponsor'){
                customer.is_self = false
            }
            members.splice(index, 1);
            // if (members.length === 0) return members.push({
            //     id: null,
            //     lead_id:0,
            //     member_name: null,
            //     relationship: null,
            //     gender: null,
            //     dob: null,
            //     age: 0,
            //     nationality_id: null,
            //     is_salary: true,
            //     declaration_answers: [],
            //     is_married: false,
            //     is_editable: true,
            //     is_temp:0
            // })
        },

        editMember(members: Array<Member>, member: Member) {
            members.map(x => {
                x.is_editable = false;
            });

            member.is_editable = true;

            members.map(x => {
                if (!x.member_name || x.dob === 'Invalid date' || !x.gender || !x.nationality_id || !x.relationship) {
                    this.deleteMember(members, members.length - 1);
                }
            })
        }
    }
}